<template>
  <div class="financial-dashboard">
    <h1>{{ title }}</h1>
    <p>
      Welcome to your financial dashboard. Here, you can track and manage your financial data efficiently.
    </p>
    <div class="summary">
      <h3>Financial Summary</h3>
      <ul>
        <li>Total Balance: {{ financialData.totalBalance }}</li>
        <li>Total Income: {{ financialData.totalIncome }}</li>
        <li>Total Expenses: {{ financialData.totalExpenses }}</li>
      </ul>
    </div>
    <div class="links">
      <h3>Useful Links</h3>
      <ul>
        <li><a href="https://www.investopedia.com" target="_blank" rel="noopener">Investopedia</a></li>
        <li><a href="https://www.financialsamurai.com" target="_blank" rel="noopener">Financial Samurai</a></li>
        <li><a href="https://www.budgetbytes.com" target="_blank" rel="noopener">Budget Bytes</a></li>
      </ul>
    </div>
    <div class="resources">
      <h3>Resources</h3>
      <ul>
        <li><a href="https://vuejs.org" target="_blank" rel="noopener">Vue.js Documentation</a></li>
        <li><a href="https://cli.vuejs.org" target="_blank" rel="noopener">Vue CLI Documentation</a></li>
        <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">Vue Router</a></li>
        <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">Vuex</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinancialDashboard',
  props: {
    title: {
      type: String,
      default: 'Financial Dashboard'
    },
    financialData: {
      type: Object,
      default: () => ({
        totalBalance: 0,
        totalIncome: 0,
        totalExpenses: 0
      })
    }
  }
}
</script>

<style scoped>
.financial-dashboard {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
  color: #2c3e50;
}
.summary, .links, .resources {
  margin-top: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 10px 0;
}
a {
  color: #42b983;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
